import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PermissionIndex from './views/Index'
import AuthMiddleware from '../../components/MiddleRoutes/AuthMiddleware'
import DashboardLayout from '../../components/Layout/DashboardLayout'

export default function PermissionFeature() {
    return (<Routes>
        <Route Component={AuthMiddleware}>
          <Route Component={DashboardLayout}>
            <Route path='/' Component={PermissionIndex} />
          </Route>
        </Route>
      </Routes>)
}
