import { CSpinner } from '@coreui/react'

export default function Overlay({show}) {
    const style={
        position: "absolute",
        display: (show===true)?'block':'none',
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: "2",
        cursor: "pointer",
        textAlign: "center"
    }
    
  return (
    <div style={style}>
      <div className='mt-8'>
        <CSpinner color="primary" variant="grow" />
        <CSpinner color="secondary" variant="grow" />
        <CSpinner color="success" variant="grow" />
        <CSpinner color="danger" variant="grow" />
        <CSpinner color="warning" variant="grow" />
        <CSpinner color="info" variant="grow" />
        <CSpinner color="light" variant="grow" />
        <CSpinner color="dark" variant="grow" />
      </div>
        
    </div>
  )
}