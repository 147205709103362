import { CButton, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useLoginModalShow } from '../../../../store'
import { useAuth } from '../../../Auth/context/AuthContext'
import hallApi from '../../api/hallApi'
import { useSocket } from '../../hooks/socketHook'
import style from '../Hall/css/BetArea.module.css'
import BetArea from './elements/BetArea'
import HallPicker from './elements/HallPicker'
import History from './elements/History'
import Party from './elements/Party'
import UserReport from './elements/UserReport'
function SingleHall({hall}) {
    console.log(hall)
    const hallId = hall.id
    const videoSource   =   hall.videoSource
    const {user} = useAuth()
    const {setLoginModalShow} = useLoginModalShow()
    const [data, setData]   =   useState({})
    const [liveStatus, setLiveStatus] = useState(false)
    const [rateWala, setRateWala] = useState(0)
    const [rateMeron, setRateMeron] = useState(0)
    const [betStatus, setBetStatus] = useState(0)
    const [fightId, setFightId] = useState(null)
    const [fightNumber, setFightNumber] = useState(null)
    useSocket({
        type: 'RATE_CHANGE',
        callBack: (payload)=>{
            switch(payload.party){
                case 'wala':
                    setRateWala(payload.rate)
                break
                case 'meron':
                    setRateMeron(payload.rate)
                break
                default:
                    break
            }
        }
    })
    useSocket({
        type: 'STATUS_CHANGE',
        callBack: (payload)=>{
            console.log('NHAN WS')
            switch (payload.status) {
                case 1:
                    console.log(payload.data)
                    const data = payload.data.fight
                    updateFightData({
                        rateWala: data.rateWala,
                        rateMeron: data.rateMeron,
                        status: data.status,
                        currentFight: data.id,
                        currentFightNumber: data.fight_number
                    })
                    break;
                case 2:
                    //Vào trận đấu
                    break;
                case 0:
                    //Hết trận đấu
                    break;
                default:
                    break;
            }
        }
    })
    useEffect(() => {
        fetchData(hallId)
    }, [hallId])
    const fetchData = async (hallId) => {
        if(hallId !== undefined){
            try {
                const data =  await hallApi.detail(hallId)
                setLiveStatus(data.hall.live === 1)
                updateFightData(data)
                return data;
            } catch (error) {
                
            }
        }
    }
    const updateFightData = (data) => {
        setRateMeron(data.rateMeron)
        setRateWala(data.rateWala)
        setBetStatus(data.status)
        setFightId(data.currentFight)
        setFightNumber(data.currentFightNumber)
    }
    if(hallId===undefined)
    return <>Loading...</>
    return (
        <CContainer xl className={style.hallContainer}>
            <CRow>
            <CCol sm={12} lg={8} className='px-2'>
                {/* <HallPlayer source={videoSource} height='480' />: */}
                <div className="content">
                    <div className='overlay'>
                        ĐANG ĐẤU
                    </div>
                    <div className='fightNumOverlay'>
                        
                    </div>
                    <div className='fightStatusOverlay'>
                        
                    </div>
                <video autoPlay controls muted style={{margin: 'auto', width: '100%', border:'10px solid #fff'}}>
                <source src="/live.mp4" type="video/mp4" />
                </video>
                </div>
                
                <Party />
            </CCol>
            <CCol sm={12} lg={4} className='px-0' style={{borderLeft: '10px solid #fff',borderRight: '10px solid #fff', background:user!=null?'':'#fff'}}>
                <HallPicker hallId={hall.key} type={hall.type}/>
                {user!=null?<BetArea hallId={hallId} fightId={fightId} fightNumber={fightNumber} rateWala={rateWala} rateMeron={rateMeron} />:<CButton color='light' shape='rounded-pill' onClick={()=>setLoginModalShow(true)} style={{marginTop: '30%', width: '100%'}}>Login / Register to start betting</CButton>}
                
            </CCol>
            </CRow>
            <CRow>
            <CCol sm={12} lg={8} className='px-2'>
                    <History hallId={hallId} />
                </CCol>
                {user!=null?<><CCol sm={12} lg={4} className='px-0'  style={{borderLeft: '10px solid #fff',borderRight: '10px solid #fff', background:'#052e66'}}>
                    <UserReport fightId={fightId} fightNumber={fightNumber} />
                </CCol></>:''}
            </CRow>

        </CContainer>
    )
}

export default SingleHall
