import { CFormCheck, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React from 'react';
import { toast } from 'react-hot-toast';
import Overlay from '../../../components/Overlay';
import { useGroups } from '../../../hooks/useGroups';
import { togglePermission, usePermission } from '../hooks/permissionHooks';

export default function PermissionTable() {
  const {data, isLoading}  = usePermission()
  const {data:groups}   = useGroups()
  if (isLoading) return <Overlay show={true} />;
  const handleToggle = async (event) => {
    try {
      await togglePermission(event.target.name, event.target.checked)
      toast("Gán quyền thành công!")
    } catch (error) {
      
    }
  }
  return (
    <CTable>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell>Quyền</CTableHeaderCell>
          <CTableHeaderCell>Path</CTableHeaderCell>
          {groups?groups.map((permission, key)=>{
            return <CTableHeaderCell key={key}>{permission.name}</CTableHeaderCell>
          }):''}
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {data?data.map((permission, key)=>{
          return (<CTableRow key={key}>
            <CTableDataCell>{permission.name}</CTableDataCell>
            <CTableDataCell>{permission.method} - {permission.permission}</CTableDataCell>
            {groups?groups.map((v, k)=>{
              let check = false;
              permission.groups.map(group=>{
                if(group.id===v.id)
                  check = true;
              })
            return (<CTableHeaderCell key={k}>
              <CFormCheck name={permission.id+'-'+v.id} defaultChecked={check} onChange={(event) => handleToggle(event)} />
            </CTableHeaderCell>)
            }):''}
          </CTableRow>)
        }):''}
      </CTableBody>
    </CTable>
  )
}
