import React from "react";
import { Route, Routes } from "react-router-dom";
import route from "./route";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import Echo from "laravel-echo"
import Pusher from "pusher-js"
export default function HomeFeature() {
  return (
    <Routes>
        <Route Component={DashboardLayout}>
          {route.map((value, key) => {
            return (
              <Route
                key={key}
                path={value.path}
                Component={value.Component}
              />
            );
          })}
        </Route>
    </Routes>
  );
}
