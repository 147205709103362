import { CButton, CCard, CCardBody, CCardFooter, CCardText, CCardTitle, CImage } from '@coreui/react'
import React from 'react'
import style from '../css/BetArea.module.css'
function PartyPick({ partyName, rate, bettings, onClick }) {
    return (
        <CCard className={`${style.partyPicker}`}>
            <CCardBody className='pb-1' style={{backgroundImage: `url('/new_theme/bet${partyName.toLowerCase()}.png')`, backgroundSize:'100% 100%'}}>
                <CCardTitle>
                   {bettings}
                    <CButton className={style.betButton} onClick={onClick}>
                        <CImage src={`/chips/chip-betting-${partyName}.png`} style={{width: '100%'}} />
                    </CButton>
                    {partyName=='DRAW'?'1:'+rate:rate}
                </CCardTitle>
            </CCardBody>
            
        </CCard>
    )
}

export default PartyPick
