import { CCard, CCardBody, CCardHeader, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import hallApi from '../../../api/hallApi'
import HistoryCircle from './historyCircle'
import style from '../css/BetArea.module.css'
function History({hallId}) {
    const [results, setResults] = useState([])
    
    useEffect(()=>{
        const fetchResult = async () => {
            try {
                const data = await hallApi.results(hallId)
                setResults(data)
            } catch (error) {
                
            }
        }
        fetchResult()
    }, [hallId])
    let resultFormated = []
    let rows = 6
    let cols = 20
    for(let i=0; i<cols; i++){
        resultFormated[i] = []
        for(let j=0; j<rows; j++){
            resultFormated[i][j] = 0
        }
    }
    let currentRow = 0
    let currentCol = 0
    resultFormated[0][0] = results[0]
    for(let m=1; m<results.length; m++){
        if(results[m]===results[m-1]){
            currentRow++
            resultFormated[currentCol][currentRow] = results[m]
        } else {
            currentCol++
            currentRow = 0
            resultFormated[currentCol][currentRow] = results[m]
        }
    }
    let finalResult = []
    for(let i=0; i<cols; i++){
        for(let j=0; j<rows; j++){
            if(finalResult[j] === undefined)
                finalResult[j] = []
            finalResult[j][i] = resultFormated[i][j]
        }
    }
    const count = []
    for(let i=0; i<results.length; i++){
        count[results[i]] = count[results[i]]?count[results[i]]+1:1
    }
    return (
        <CCard style={{borderRadius:0}}>
            <CCardHeader style={{ fontWeight: 'bold'}}>
                <span className={`me-4  ${style.winCount}`}>
                    <img src='/new_theme/countmeron.png' />
                </span>
                <span className={`me-4  ${style.winCount}`}>
                 <img src='/new_theme/countwala.png' />
                </span>
                <span className={`me-4  ${style.winCount}`}>
                <img src='/new_theme/countdraw.png' />
                </span>
                <span className={`me-4 ${style.winCount}`}>
               <img src='/new_theme/countcancel.png' />
                </span>
            </CCardHeader>
            <CCardBody>
            <CTable bordered responsive="sm" align='middle'>
                <CTableBody>
                    {finalResult.map((value, key)=>{
                        return <CTableRow key={key}>
                            {value.map((v, k)=>{
                                return <CTableDataCell key={k} style={{padding:0, textAlign:'center', verticalAlign:'middle',width:'38px',height:'38px', paddingTop:'4px'}}>{v!=0?<HistoryCircle type={v} />:''} </CTableDataCell>
                            })}
                        </CTableRow>
                    })}
                </CTableBody>
            </CTable>
            </CCardBody>
        </CCard>
    )
}

export default History
