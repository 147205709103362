import { CFormInput, CTable, CTableBody, CTableDataCell, CTableHead, CTableRow } from "@coreui/react";
import React from "react";
import Overlay from "../../../components/Overlay";
import { useUnregistedPermissions } from "../hooks/permissionHooks";
import SinglePermissionAdd from "./SinglePermissionAdd";

export default function UnRegistedPermissions() {
  const { data, isLoading, isError } = useUnregistedPermissions();
  if (isLoading) return <Overlay show={true} />;
  console.log(data);
  return (<div>
    <CTable>
        <CTableHead>
            <CTableRow>
                <CTableDataCell>Tên quyền</CTableDataCell>
                <CTableDataCell>URL</CTableDataCell>
                <CTableDataCell>Phương thức</CTableDataCell>
                <CTableDataCell></CTableDataCell>
            </CTableRow>
        </CTableHead>
        <CTableBody>
            {
                data.map((value, key)=>{
                    return <SinglePermissionAdd key={key} value={value} />
                })
            }
        </CTableBody>
    </CTable>
  </div>);
}
