import Echo from "laravel-echo";

export function createSocketConnection() {
  if (!window.Echo) {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '90dc6c9608eee3dd9501',
        cluster: 'ap1',
        forceTLS: true
    })
  }
}