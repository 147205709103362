import React from 'react'
import useSWR from 'swr'
import swrFetcher from '../../../api/swrFetcher';
import { CButton } from '@coreui/react';
import axiosClient from '../../../api/axiosClient';

export default function UserList() {
    const {data} = useSWR({url: '/api/user'}, swrFetcher);
    console.log(data);
  return (
    <div>
        {data?JSON.stringify(data):''}
        <CButton color='primary' onClick={()=>{
            axiosClient.get('auth/logout');
        }}>Logout</CButton>
    </div>
  )
}
