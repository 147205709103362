import { cilCheckCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CFormInput, CSpinner, CTableDataCell, CTableRow } from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useForm } from 'react-hook-form'
import { permissionApi } from '../api/permissionApi'
import { createPermissionValidator } from '../validator/createPermissionValidator'

export default function SinglePermissionAdd({value}) {
  const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: {isSubmitting},
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
          name:value.permission,
          permission: value.permission,
          method: value.method
        },
        resolver: yupResolver(createPermissionValidator),
      })
      if(isSubmitting)
        console.log('submitting');
    const onSubmit = async (data) => {
      
        try {
            await permissionApi.createPermissions(data)
            queryClient.invalidateQueries('permissionsList')
            queryClient.invalidateQueries('unregistedPermissions')
        } catch (error) {
            
        }
    }
    return (
            <CTableRow>
            <CTableDataCell><CFormInput {...register('name')} /></CTableDataCell>
            <CTableDataCell><CFormInput readOnly {...register('permission')} /></CTableDataCell>
            <CTableDataCell><CFormInput readOnly {...register('method')} /></CTableDataCell>
            <CTableDataCell><CButton onClick={handleSubmit(onSubmit)} color='primary' disabled={isSubmitting}>{!isSubmitting?<CIcon icon={cilCheckCircle}/>:<CSpinner size='sm' />}Save</CButton></CTableDataCell>
            </CTableRow>
        
    )
}

