import React from 'react'
import LiveDotCss from '../css/LiveDot.module.css'
function LiveDot() {
    return (
        <svg height="6" width="6" className='blinking'> 
        <circle cx="3" cy="3" r="3" fill="red" />
        Sorry, your browser does not support inline SVG.  
        </svg> 
    )
}

export default LiveDot
