import { CCol, CImage, CRow } from '@coreui/react'
import React from 'react'
import style from '../css/BetArea.module.css'
function Party() {
    return (
        <CRow className={`${style.partyBar} mx-0`}>      
            <CCol xs={5} className={`${style.partyWala} text-end`}>
                <CImage src='/new_theme/stars.png' style={{float:'left'}} className={style.partyStars} />
                <CImage src='/new_theme/wala.png' style={{marginLeft: '10px', float:'left', height:'36px'}} className={style.partyName} />
            </CCol>
            <CCol xs={2} className='text-center' style={{marginTop:'16px'}}>
                <CImage src='/new_theme/vs.png' style={{width: '60%'}} className={style.versus} />
            </CCol>
            
            <CCol xs={5} className={`${style.partyMeron}`}>
                <CImage src='/new_theme/meron.png' style={{marginLeft: '10px',float:'left', height:'36px'}}  className={style.partyName}/>
                <CImage src='/new_theme/stars.png' style={{float:'right'}} className={style.partyStars}/>
            </CCol>
        </CRow>
    )
}

export default Party
