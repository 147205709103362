import { CButton, CButtonGroup } from '@coreui/react'
import React from 'react'
import style from '../css/BetArea.module.css'
import LiveDot from './LiveDot'
import useHall from '../../../hooks/useHall'
function HallPicker({hallId, type}) {
    const {data, isLoading} = useHall({type})
    const viewing = hallId
    if(isLoading)
        return <CButtonGroup size='lg' role="group" aria-label="Pick hall">Loading...</CButtonGroup>
    return ( 
        <CButtonGroup size='lg' role="group" aria-label="Pick hall">
            {data.map((value, key)=>{
                return <CButton color="primary" key={key} disabled={value.live==0} className={`${style.hallPicker} ${value.key==viewing?style.hallViewing:''}`} >{value.key} {value.live==1?<LiveDot/>:''}</CButton>
            })}
            
        </CButtonGroup>
    )
}

export default HallPicker
