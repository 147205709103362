import { useQuery } from "@tanstack/react-query";
import { permissionApi } from "../api/permissionApi";

function usePermission(params){
    return useQuery({
        queryKey: ['permissionsList'],
        queryFn: () =>permissionApi.getPermissions(params),
    })
}
function useUnregistedPermissions(){
    return useQuery({
        queryKey: ['unregistedPermissions'],
        queryFn: () =>permissionApi.getUnregisterdPermissions(),
    })
}
function togglePermission(id,state){
    return permissionApi.assignPermission(id, state)
}
export { togglePermission, usePermission, useUnregistedPermissions };
