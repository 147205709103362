import axiosClient from "../../../api/axiosClient";

const betApi = {
    info(fightId){
        return axiosClient.get('/bet/info/'+fightId)
    },
    placeBet(payload){
        return axiosClient.post('/bet/placeBet', payload)
    }
}

export default betApi;