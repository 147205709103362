import { useQuery } from "@tanstack/react-query"
import profileApi from "../api/profileApi"

function useProfile() {
    return useQuery({
        queryKey: ['profile'],
        queryFn: () =>profileApi.get(),
      })
}

export default useProfile
