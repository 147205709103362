import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserList from './UserList/UserList'
import AuthMiddleware from '../../components/MiddleRoutes/AuthMiddleware'
import UserProfile from './Profile/UserProfile'
import DashboardLayout from '../../components/Layout/DashboardLayout'

export default function UserFeature() {
  return (
    <Routes>
      <Route Component={AuthMiddleware}>
        <Route Component={DashboardLayout}>
          <Route path='/' Component={UserList} />
          <Route path='/profile' Component={UserProfile} />
        </Route>
      </Route>
    </Routes>

  )
}
