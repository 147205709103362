import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login/Login'

export default function AuthFeature() {
  return (
      <Routes>
        <Route path='/login' Component={Login}/>
      </Routes>
  )
}
