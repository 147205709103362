import "@coreui/coreui/dist/css/coreui.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useEffect } from 'react';
import 'react-nestable/dist/styles/index.css';
import "video-react/dist/video-react.css";
import { Route, Routes } from 'react-router-dom';
import csrfToken from './api/csrfToken';
import AuthProvider from './features/Auth/context/AuthContext';
import { mainRoutes } from './routes';

function App() {
  useEffect(()=>{
    csrfToken.get();
  })
  return (
    <AuthProvider>
      <Routes>
        {mainRoutes.map((value, key)=>{
          return (<Route key={key} path={value.path} Component={value.Component} />)
        })}
      </Routes>
    </AuthProvider>
      
    
  );
}

export default App;
