import {
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import style from "../css/BetArea.module.css";
function UserReport({fightId, fightNumber}) {
  const [activeKey, setActiveKey] = useState(1);
  const [betting, setBetting] = useState([])
  
  const badgeColor = {
    wala: "primary",
    meron: "danger",
    draw: "success",
  };
  
  const handleChange = () => {
    const userBetting = localStorage.getItem('betting-'+fightId)?JSON.parse(localStorage.getItem('betting-'+fightId)):[];
    setBetting(userBetting)
  }
  useEffect(()=>{
    window.addEventListener('bettingChange', handleChange)
  })
  useEffect(()=>{
    handleChange()
  }, [fightId])
  return (
    <>
      <CNav
        variant="tabs"
        role="tablist"
      >
        <CNavItem>
          <CNavLink
            href="#!"
            active={activeKey === 1}
            onClick={() => setActiveKey(1)}
            className={`${style.userReportPicker} ${
              activeKey === 1 ? style.userReportPickerActive : ""
            }`}
          >
            Current Bets
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#!"
            active={activeKey === 2}
            onClick={() => setActiveKey(2)}
            className={`${style.userReportPicker} ${
              activeKey === 2 ? style.userReportPickerActive : ""
            }`}
          >
            Today Report
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="#!"
            active={activeKey === 3}
            onClick={() => setActiveKey(3)}
            className={`${style.userReportPicker} ${
              activeKey === 3 ? style.userReportPickerActive : ""
            }`}
          >
            Game Law
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        {}
        <CTabPane
          role="tabpanel"
          aria-labelledby="home-tab"
          visible={activeKey === 1}
        >
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>#Fight</CTableHeaderCell>
                <CTableHeaderCell>BET</CTableHeaderCell>
                <CTableHeaderCell>$</CTableHeaderCell>
                <CTableHeaderCell>Rate</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {betting?betting.map((value, key) => {
                return (
                  <CTableRow key={key}>
                    <CTableDataCell>{fightNumber}</CTableDataCell>
                    <CTableDataCell>
                      <CBadge
                        color={badgeColor[value.party]}
                        style={{ textTransform: "uppercase" }}
                      >
                        {value.party}
                      </CBadge>
                    </CTableDataCell>
                    <CTableDataCell>{value.amount}</CTableDataCell>
                    <CTableDataCell>{value.rate}</CTableDataCell>
                  </CTableRow>
                );
              }):''}
            </CTableBody>
          </CTable>
        </CTabPane>
        <CTabPane
          role="tabpanel"
          aria-labelledby="profile-tab"
          visible={activeKey === 2}
        ></CTabPane>
        <CTabPane
          role="tabpanel"
          aria-labelledby="law-tab"
          visible={activeKey === 3}
          style={{backgroundColor: '#fff', maxHeight:'302px',overflowY: 'scroll'}}
          className="pt-3"
        >
          <ol>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>WALA</strong>: Cricket with a “WHITE SPOT” on its body
                (as shown in the image).
              </p>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>MERON</strong>: Cricket without any spot on its body (as
                shown in the image).
              </p>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>“GHÉP DẾ (PAIRING)”</strong>: The technician pairing two
                cricket..
              </p>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>“ĐẶT CƯỢC (BETTING)”</strong>: The countdown starts at
                00:03:00 when the BET notification appears.
              </p>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>“THI ĐẤU (FIGHTING)”</strong>: The technician releases
                two cricket, and the match is counted when two cricket bite each
                other.
              </p>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>Result “WIN”</strong>:
              </p>
              <ul>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    <strong>LOSE</strong>: If the cricket runs away and when the
                    technician stimulate, it does NOT bite, crow (flap wings),
                    or run.
                  </p>
                </li>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    <strong>WIN</strong>: The remaining cricket wins, and when
                    the technician stimulate, it continues to bite and crow
                    (flap wings).
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>Result “CANCEL”</strong>:
              </p>
              <ul>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    <strong>“CANCEL”</strong>: When the arena opens for 
                    <strong>"FIGHTING", </strong>one of the two cricket competes,
                    and the other cricket does NOT compete. The non-competing
                    cricket does NOT bite, crow (flap wings), or run.
                  </p>
                </li>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    <strong>“CANCEL”</strong>: When the arena opens for 
                    <strong>"FIGHTING"</strong>, both cricket do NOT compete.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p style={{ marginLeft: 0 }}>
                <strong>Result “DRAW”</strong>:
              </p>
              <ul>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    When the arena opens for <strong>"FIGHTING"</strong>, both cricket
                    have competed (with NO specified time limit).
                  </p>
                </li>
                <li>
                  <p style={{ marginLeft: 0 }}>
                    Either both cricket run, and when, stimulate, both of them
                    does NOT bite, crow (flap wings), or run.
                  </p>
                </li>
                <li>
                  <p style={{ marginLeft: 0 }}>Or both cricket die.</p>
                </li>
              </ul>
            </li>
          </ol>
        </CTabPane>
      </CTabContent>
    </>
  );
}

export default UserReport;
